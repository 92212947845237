<template>
  <div style="width: 100%; height: 100%; background: #f8f8f8">
    <div class="pageTitle">
      <div
        style="left: 0.4rem; position: absolute; color: #fff"
        @click="goback"
      >
        <span><van-icon name="arrow-left" /></span>
      </div>
      <div class="title">经营数据</div>
    </div>
    <van-tabs v-model="active" sticky animated swipeable>
      <van-tab title="经营日报">
        <div style="overflow: auto; height: 18rem">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onDayDataMore"
            :offset="5"
          >
            <div v-for="item in dayData" :key="item.xName">
              <van-cell style="background: #f8f8f8">
                <div>
                  <span style="color: #b7b7b7">{{
                    formatDate(item.xName)
                  }}</span>
                  <div
                    style="
                      background: #fff;
                      min-height: 3.2rem;
                      border-radius: 0.2rem;
                    "
                  >
                    <div style="padding: 0.5rem 0 0 0.5rem">
                      <span style="font-size: 0.8rem; color: #1b89fa">¥</span>
                      <span
                        style="color: #1b89fa; font-size: 2em; font-weight: 400"
                        >{{ formatPrice(item.amount) }}</span
                      >
                    </div>
                    <div
                      style="
                        padding: 0.2rem 0 0 0.5rem;
                        font-size: 1em;
                        color: #a6a6a6;
                      "
                    >
                      <span>{{
                        item.amount > 0
                          ? "共 " + item.count + " 笔订单"
                          : "当日无交易"
                      }}</span>
                    </div>
                  </div>
                </div>
              </van-cell>
            </div>
          </van-list>
        </div>
      </van-tab>
      <van-tab title="经营月报">
        <div style="overflow: auto; height: 18rem">
          <van-list
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad"
            :offset="5"
          >
            <div v-for="item in monthData" :key="item.xName">
              <van-cell style="background: #f8f8f8">
                <div>
                  <span style="color: #b7b7b7">{{
                    formatMonthDate(item.xName)
                  }}</span>
                  <div
                    style="
                      background: #fff;
                      min-height: 3.2rem;
                      border-radius: 0.2rem;
                    "
                  >
                    <div style="padding: 0.5rem 0 0 0.5rem">
                      <span style="font-size: 0.8rem; color: #1b89fa">¥</span>
                      <span
                        style="color: #1b89fa; font-size: 2em; font-weight: 400"
                        >{{ formatPrice(item.amount) }}</span
                      >
                    </div>
                    <div
                      style="
                        padding: 0.2rem 0 0 0.5rem;
                        font-size: 1em;
                        color: #a6a6a6;
                      "
                    >
                      <span>{{
                        item.amount > 0
                          ? "共 " + item.count + " 笔订单"
                          : "当日无交易"
                      }}</span>
                    </div>
                  </div>
                </div>
              </van-cell>
            </div>
          </van-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Tabs, Tab, List, Cell, Icon } from "vant";
import { statisticsData } from "../../api/merchant";
import { mapState } from "vuex";
export default {
  name: "MerchantBizData",
  components: {
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
  },
  data() {
    return {
      loading: false,
      finished: false,
      dayData: [],
      monthData: [],
      pageNumber: 1,
      pageNumber1: 1,
      pageSize: 3,
      active: 0,
      day_type: 3,
      month_type: 4,
    };
  },
  computed: {
    ...mapState({
      merId: (state) => state.base.merchantId,
    }),
  },
  created() {
    this.getDayData();
  },
  watch: {
    active(val) {
      if (0 === val) {
        this.dayData = [];
        this.getDayData();
      } else {
        this.monthData = [];
        this.getMonthData();
      }
    },
    $route(to) {
      if (to.name === "MerchantBizData") {
        this.getDayData();
        this.getMonthData();
      }
    },
  },
  methods: {
    getDayData() {
      statisticsData({
        mer_id: this.merId,
        data_type: this.day_type,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
        .then((res) => {
          let rows = res.data.chartData.content;
          this.loading = false;
          this.total = res.data.chartData.totalElements;
          if (rows == null || rows.length === 0) {
            this.finished = true;
            return;
          }
          this.dayData = this.dayData.concat(rows);
          if (this.dayData.length >= this.total) {
            this.finished = true;
          }
        })
        .catch((res) => {
          this.loading = false;
          this.$toast.fail(res);
        });
    },
    getMonthData() {
      statisticsData({
        mer_id: this.merId,
        data_type: this.month_type,
        pageNumber: this.pageNumber1,
        pageSize: this.pageSize,
      })
        .then((res) => {
          let rows = res.data.chartData.content;
          this.loading = false;
          this.total = res.data.chartData.totalElements;
          if (rows == null || rows.length === 0) {
            this.finished = true;
            return;
          }
          this.monthData = this.monthData.concat(rows);
          if (this.monthData.length >= this.total) {
            this.finished = true;
          }
        })
        .catch((res) => {
          this.loading = false;
          this.$toast.fail(res);
        });
    },
    formatPrice(price) {
      return price == undefined ? 0 : (price / 100).toFixed(2);
    },
    formatDate(date) {
      return date.substr(5, date.length).replace("-", "月") + "日";
    },
    formatMonthDate(date) {
      return date.replace("-", "年") + "月";
    },
    onDayDataMore() {
      this.pageNumber++;
      this.getDayData();
    },
    onMonthDataMore() {
      this.pageNumber1++;
      this.getMonthData();
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.order-list {
  padding: 0.8rem 0.3rem 0.3rem 0.3rem;
  color: @mch-color;
  display: flex;
  font-size: 0.8rem;
  justify-content: space-around;
  box-shadow: 30rem 0 0 30rem #f8f8f8;
}
/deep/ .van-tabs__line {
  background-color: @mch-color;
}
</style>
